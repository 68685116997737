import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _73e587a7 = () => interopDefault(import('../app/pages/campaigns.vue' /* webpackChunkName: "pages/campaigns" */))
const _36de9cf8 = () => interopDefault(import('../app/pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _e26cbe30 = () => interopDefault(import('../app/pages/esports.vue' /* webpackChunkName: "pages/esports" */))
const _417450ad = () => interopDefault(import('../app/pages/help.vue' /* webpackChunkName: "pages/help" */))
const _3be758ad = () => interopDefault(import('../app/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _788bf045 = () => interopDefault(import('../app/pages/nft_games/index.vue' /* webpackChunkName: "pages/nft_games/index" */))
const _13d273b7 = () => interopDefault(import('../app/pages/oauth-authenticate.vue' /* webpackChunkName: "pages/oauth-authenticate" */))
const _29a54a4c = () => interopDefault(import('../app/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _17b7c3d2 = () => interopDefault(import('../app/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _e544abf8 = () => interopDefault(import('../app/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _011f4340 = () => interopDefault(import('../app/pages/signup-login.vue' /* webpackChunkName: "pages/signup-login" */))
const _1c3d73cb = () => interopDefault(import('../app/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _fcafadcc = () => interopDefault(import('../app/pages/token-use-guideline.vue' /* webpackChunkName: "pages/token-use-guideline" */))
const _c1272b7a = () => interopDefault(import('../app/pages/token-use-guidline.vue' /* webpackChunkName: "pages/token-use-guidline" */))
const _6707751a = () => interopDefault(import('../app/pages/articles/popular.vue' /* webpackChunkName: "pages/articles/popular" */))
const _30eb06b1 = () => interopDefault(import('../app/pages/articles/recent.vue' /* webpackChunkName: "pages/articles/recent" */))
const _4cc21514 = () => interopDefault(import('../app/pages/callback/facebook.vue' /* webpackChunkName: "pages/callback/facebook" */))
const _1afeae42 = () => interopDefault(import('../app/pages/callback/line.vue' /* webpackChunkName: "pages/callback/line" */))
const _0da3bbd6 = () => interopDefault(import('../app/pages/callback/twitter.vue' /* webpackChunkName: "pages/callback/twitter" */))
const _6465abe2 = () => interopDefault(import('../app/pages/callback/yahoo.vue' /* webpackChunkName: "pages/callback/yahoo" */))
const _d6042fc6 = () => interopDefault(import('../app/pages/me/notifications.vue' /* webpackChunkName: "pages/me/notifications" */))
const _2664370d = () => interopDefault(import('../app/pages/me/token_history_csv_download.vue' /* webpackChunkName: "pages/me/token_history_csv_download" */))
const _353e285f = () => interopDefault(import('../app/pages/media_embed/any.vue' /* webpackChunkName: "pages/media_embed/any" */))
const _652dc45c = () => interopDefault(import('../app/pages/articles/ranking/tip.vue' /* webpackChunkName: "pages/articles/ranking/tip" */))
const _32ae73f1 = () => interopDefault(import('../app/pages/category/crypto/coin_ranking.vue' /* webpackChunkName: "pages/category/crypto/coin_ranking" */))
const _cb82170e = () => interopDefault(import('../app/pages/labo/n/license_token.vue' /* webpackChunkName: "pages/labo/n/license_token" */))
const _3b784906 = () => interopDefault(import('../app/pages/labo/n/majority_judgement.vue' /* webpackChunkName: "pages/labo/n/majority_judgement" */))
const _3df989c0 = () => interopDefault(import('../app/pages/labo/n/quadratic_voting.vue' /* webpackChunkName: "pages/labo/n/quadratic_voting" */))
const _223e09b5 = () => interopDefault(import('../app/pages/labo/n/random.vue' /* webpackChunkName: "pages/labo/n/random" */))
const _fae39bae = () => interopDefault(import('../app/pages/me/articles/new.vue' /* webpackChunkName: "pages/me/articles/new" */))
const _9edefc68 = () => interopDefault(import('../app/pages/me/articles/purchased.vue' /* webpackChunkName: "pages/me/articles/purchased" */))
const _8704ac72 = () => interopDefault(import('../app/pages/me/settings/applications/index.vue' /* webpackChunkName: "pages/me/settings/applications/index" */))
const _62828443 = () => interopDefault(import('../app/pages/me/settings/mute_users.vue' /* webpackChunkName: "pages/me/settings/mute_users" */))
const _48d2313e = () => interopDefault(import('../app/pages/me/settings/sessions.vue' /* webpackChunkName: "pages/me/settings/sessions" */))
const _5ea1e7ba = () => interopDefault(import('../app/pages/me/wallet/deposit.vue' /* webpackChunkName: "pages/me/wallet/deposit" */))
const _14b821ce = () => interopDefault(import('../app/pages/me/wallet/history.vue' /* webpackChunkName: "pages/me/wallet/history" */))
const _d0ccb756 = () => interopDefault(import('../app/pages/me/wallet/withdraw.vue' /* webpackChunkName: "pages/me/wallet/withdraw" */))
const _44df46d5 = () => interopDefault(import('../app/pages/me/settings/applications/new.vue' /* webpackChunkName: "pages/me/settings/applications/new" */))
const _059cb322 = () => interopDefault(import('../app/pages/me/articles/draft/v2/_articleId/content_edit_histories.vue' /* webpackChunkName: "pages/me/articles/draft/v2/_articleId/content_edit_histories" */))
const _1e0e5ca9 = () => interopDefault(import('../app/pages/me/articles/draft/v2/_articleId/edit.vue' /* webpackChunkName: "pages/me/articles/draft/v2/_articleId/edit" */))
const _c246c148 = () => interopDefault(import('../app/pages/me/articles/public/v2/_articleId/content_edit_histories.vue' /* webpackChunkName: "pages/me/articles/public/v2/_articleId/content_edit_histories" */))
const _39de793a = () => interopDefault(import('../app/pages/me/articles/public/v2/_articleId/edit.vue' /* webpackChunkName: "pages/me/articles/public/v2/_articleId/edit" */))
const _5994bfbc = () => interopDefault(import('../app/pages/me/articles/draft/_articleId/edit.vue' /* webpackChunkName: "pages/me/articles/draft/_articleId/edit" */))
const _5e148933 = () => interopDefault(import('../app/pages/me/articles/draft/_articleId/paypart.vue' /* webpackChunkName: "pages/me/articles/draft/_articleId/paypart" */))
const _1fa943a8 = () => interopDefault(import('../app/pages/me/articles/public/_articleId/edit.vue' /* webpackChunkName: "pages/me/articles/public/_articleId/edit" */))
const _2316efed = () => interopDefault(import('../app/pages/me/articles/public/_articleId/paypart.vue' /* webpackChunkName: "pages/me/articles/public/_articleId/paypart" */))
const _eb9d7fe2 = () => interopDefault(import('../app/pages/me/settings/applications/_clientId/edit.vue' /* webpackChunkName: "pages/me/settings/applications/_clientId/edit" */))
const _4c600f56 = () => interopDefault(import('../app/pages/media_embed/twitter_profile/_userName.vue' /* webpackChunkName: "pages/media_embed/twitter_profile/_userName" */))
const _66ba4fc8 = () => interopDefault(import('../app/pages/tag/_tag.vue' /* webpackChunkName: "pages/tag/_tag" */))
const _e4e9e3b6 = () => interopDefault(import('../app/pages/users/_userId/index.vue' /* webpackChunkName: "pages/users/_userId/index" */))
const _3b9ab076 = () => interopDefault(import('../app/pages/users/_userId/badge.vue' /* webpackChunkName: "pages/users/_userId/badge" */))
const _acd79fa2 = () => interopDefault(import('../app/pages/users/_userId/drafts.vue' /* webpackChunkName: "pages/users/_userId/drafts" */))
const _723fb596 = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _080d873e = () => interopDefault(import('../app/pages/_userId/articles/_articleId/index.vue' /* webpackChunkName: "pages/_userId/articles/_articleId/index" */))
const _f734ea90 = () => interopDefault(import('../app/pages/_userId/articles/_articleId/supporters.vue' /* webpackChunkName: "pages/_userId/articles/_articleId/supporters" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaigns",
    component: _73e587a7,
    name: "campaigns"
  }, {
    path: "/confirm",
    component: _36de9cf8,
    name: "confirm"
  }, {
    path: "/esports",
    component: _e26cbe30,
    name: "esports"
  }, {
    path: "/help",
    component: _417450ad,
    name: "help"
  }, {
    path: "/login",
    component: _3be758ad,
    name: "login"
  }, {
    path: "/nft_games",
    component: _788bf045,
    name: "nft_games"
  }, {
    path: "/oauth-authenticate",
    component: _13d273b7,
    name: "oauth-authenticate"
  }, {
    path: "/privacy",
    component: _29a54a4c,
    name: "privacy"
  }, {
    path: "/search",
    component: _17b7c3d2,
    name: "search"
  }, {
    path: "/signup",
    component: _e544abf8,
    name: "signup"
  }, {
    path: "/signup-login",
    component: _011f4340,
    name: "signup-login"
  }, {
    path: "/terms",
    component: _1c3d73cb,
    name: "terms"
  }, {
    path: "/token-use-guideline",
    component: _fcafadcc,
    name: "token-use-guideline"
  }, {
    path: "/token-use-guidline",
    component: _c1272b7a,
    name: "token-use-guidline"
  }, {
    path: "/articles/popular",
    component: _6707751a,
    name: "articles-popular"
  }, {
    path: "/articles/recent",
    component: _30eb06b1,
    name: "articles-recent"
  }, {
    path: "/callback/facebook",
    component: _4cc21514,
    name: "callback-facebook"
  }, {
    path: "/callback/line",
    component: _1afeae42,
    name: "callback-line"
  }, {
    path: "/callback/twitter",
    component: _0da3bbd6,
    name: "callback-twitter"
  }, {
    path: "/callback/yahoo",
    component: _6465abe2,
    name: "callback-yahoo"
  }, {
    path: "/me/notifications",
    component: _d6042fc6,
    name: "me-notifications"
  }, {
    path: "/me/token_history_csv_download",
    component: _2664370d,
    name: "me-token_history_csv_download"
  }, {
    path: "/media_embed/any",
    component: _353e285f,
    name: "media_embed-any"
  }, {
    path: "/articles/ranking/tip",
    component: _652dc45c,
    name: "articles-ranking-tip"
  }, {
    path: "/category/crypto/coin_ranking",
    component: _32ae73f1,
    name: "category-crypto-coin_ranking"
  }, {
    path: "/labo/n/license_token",
    component: _cb82170e,
    name: "labo-n-license_token"
  }, {
    path: "/labo/n/majority_judgement",
    component: _3b784906,
    name: "labo-n-majority_judgement"
  }, {
    path: "/labo/n/quadratic_voting",
    component: _3df989c0,
    name: "labo-n-quadratic_voting"
  }, {
    path: "/labo/n/random",
    component: _223e09b5,
    name: "labo-n-random"
  }, {
    path: "/me/articles/new",
    component: _fae39bae,
    name: "me-articles-new"
  }, {
    path: "/me/articles/purchased",
    component: _9edefc68,
    name: "me-articles-purchased"
  }, {
    path: "/me/settings/applications",
    component: _8704ac72,
    name: "me-settings-applications"
  }, {
    path: "/me/settings/mute_users",
    component: _62828443,
    name: "me-settings-mute_users"
  }, {
    path: "/me/settings/sessions",
    component: _48d2313e,
    name: "me-settings-sessions"
  }, {
    path: "/me/wallet/deposit",
    component: _5ea1e7ba,
    name: "me-wallet-deposit"
  }, {
    path: "/me/wallet/history",
    component: _14b821ce,
    name: "me-wallet-history"
  }, {
    path: "/me/wallet/withdraw",
    component: _d0ccb756,
    name: "me-wallet-withdraw"
  }, {
    path: "/me/settings/applications/new",
    component: _44df46d5,
    name: "me-settings-applications-new"
  }, {
    path: "/me/articles/draft/v2/:articleId?/content_edit_histories",
    component: _059cb322,
    name: "me-articles-draft-v2-articleId-content_edit_histories"
  }, {
    path: "/me/articles/draft/v2/:articleId?/edit",
    component: _1e0e5ca9,
    name: "me-articles-draft-v2-articleId-edit"
  }, {
    path: "/me/articles/public/v2/:articleId?/content_edit_histories",
    component: _c246c148,
    name: "me-articles-public-v2-articleId-content_edit_histories"
  }, {
    path: "/me/articles/public/v2/:articleId?/edit",
    component: _39de793a,
    name: "me-articles-public-v2-articleId-edit"
  }, {
    path: "/me/articles/draft/:articleId?/edit",
    component: _5994bfbc,
    name: "me-articles-draft-articleId-edit"
  }, {
    path: "/me/articles/draft/:articleId?/paypart",
    component: _5e148933,
    name: "me-articles-draft-articleId-paypart"
  }, {
    path: "/me/articles/public/:articleId?/edit",
    component: _1fa943a8,
    name: "me-articles-public-articleId-edit"
  }, {
    path: "/me/articles/public/:articleId?/paypart",
    component: _2316efed,
    name: "me-articles-public-articleId-paypart"
  }, {
    path: "/me/settings/applications/:clientId/edit",
    component: _eb9d7fe2,
    name: "me-settings-applications-clientId-edit"
  }, {
    path: "/media_embed/twitter_profile/:userName?",
    component: _4c600f56,
    name: "media_embed-twitter_profile-userName"
  }, {
    path: "/tag/:tag?",
    component: _66ba4fc8,
    name: "tag-tag"
  }, {
    path: "/users/:userId",
    component: _e4e9e3b6,
    name: "users-userId"
  }, {
    path: "/users/:userId?/badge",
    component: _3b9ab076,
    name: "users-userId-badge"
  }, {
    path: "/users/:userId?/drafts",
    component: _acd79fa2,
    name: "users-userId-drafts"
  }, {
    path: "/",
    component: _723fb596,
    name: "index"
  }, {
    path: "/:userId/articles/:articleId",
    component: _080d873e,
    name: "userId-articles-articleId"
  }, {
    path: "/:userId/articles/:articleId?/supporters",
    component: _f734ea90,
    name: "userId-articles-articleId-supporters"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
