// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/pc/common/image_bg404.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app-header[data-v-54df7969]{margin:0 auto;height:100px}.page-404-container[data-v-54df7969],.page-500-container[data-v-54df7969]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:bottom;background-size:600px;text-align:center;width:100%;height:100%}.page-404-container .title[data-v-54df7969],.page-500-container .title[data-v-54df7969]{color:#030303;font-size:14px;font-weight:700;line-height:1.5;margin:40px 0 0}.page-404-container .description[data-v-54df7969],.page-500-container .description[data-v-54df7969]{color:#6e6e6e;font-size:12px;line-height:18px;margin:20px 0 0}.page-404-container .to-top-button[data-v-54df7969],.page-500-container .to-top-button[data-v-54df7969]{margin:40vh auto 150px}.page-500-container[data-v-54df7969]{background:none}.page-500-container .description[data-v-54df7969]{margin:38px 30px 50px}@media screen and (max-width:550px){.br[data-v-54df7969]:before{content:\"\\A\";white-space:pre}.app-header[data-v-54df7969]{height:66px}.page-404-container[data-v-54df7969]{background-size:400px}.page-404-container .title[data-v-54df7969]{margin-top:30px}.page-404-container .to-top-button[data-v-54df7969]{margin:36vh auto 80px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
