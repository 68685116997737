// user
export const SET_LOGGED_IN = 'SET_LOGGED_IN'
export const SET_STATUS = 'SET_STATUS'
export const SET_SIGN_UP_MODAL = 'SET_SIGN_UP_MODAL'
export const SET_SENT_MAIL = 'SET_SENT_MAIL'
export const SET_SIGN_UP_USER_ID = 'SET_SIGN_UP_USER_ID'
export const SET_SIGN_UP_EMAIL = 'SET_SIGN_UP_EMAIL'
export const SET_SIGN_UP_PASSWORD = 'SET_SIGN_UP_PASSWORD'
export const SHOW_SIGN_UP_ERROR = 'SHOW_SIGN_UP_ERROR'
export const HIDE_SIGN_UP_ERROR = 'HIDE_SIGN_UP_ERROR'
export const HIDE_SIGN_UP_ERRORS = 'HIDE_SIGN_UP_ERRORS'
export const SET_SIGN_UP_AUTH_FLOW_MODAL = 'SET_SIGN_UP_AUTH_FLOW_MODAL'
export const SET_SIGN_UP_AUTH_FLOW_LOGIN_MODAL = 'SET_SIGN_UP_AUTH_FLOW_LOGIN_MODAL'
export const SET_SIGN_UP_AUTH_FLOW_LOGIN_USER_ID_OR_EMAIL =
  'SET_SIGN_UP_AUTH_FLOW_LOGIN_USER_ID_OR_EMAIL'
export const SET_SIGN_UP_AUTH_FLOW_LOGIN_PASSWORD = 'SET_SIGN_UP_AUTH_FLOW_LOGIN_PASSWORD'
export const SHOW_SIGN_UP_AUTH_FLOW_LOGIN_ERROR = 'SHOW_SIGN_UP_AUTH_FLOW_LOGIN_ERROR'
export const HIDE_SIGN_UP_AUTH_FLOW_LOGIN_ERROR = 'HIDE_SIGN_UP_AUTH_FLOW_LOGIN_ERROR'
export const SET_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_MODAL =
  'SET_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_MODAL'
export const SET_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_PHONE_NUMBER =
  'SET_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_PHONE_NUMBER'
export const SHOW_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_ERROR =
  'SHOW_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_ERROR'
export const HIDE_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_ERROR =
  'HIDE_SIGN_UP_AUTH_FLOW_INPUT_PHONE_NUMBER_ERROR'
export const SET_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_MODAL =
  'SET_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_MODAL'
export const SET_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_AUTH_CODE =
  'SET_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_AUTH_CODE'
export const SHOW_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_ERROR =
  'SHOW_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_ERROR'
export const HIDE_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_ERROR =
  'HIDE_SIGN_UP_AUTH_FLOW_INPUT_AUTH_CODE_ERROR'
export const SET_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD_MODAL =
  'SET_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD_MODAL'
export const SET_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD =
  'SET_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD'
export const SHOW_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD_ERROR =
  'SHOW_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD'
export const HIDE_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD_ERROR =
  'HIDE_SIGN_UP_AUTH_FLOW_INPUT_WALLET_PASSWORD'
export const SET_LOGIN_MODAL = 'SET_LOGIN_MODAL'
export const SET_LOGIN_USER_ID_OR_EMAIL = 'SET_LOGIN_USER_ID_OR_EMAIL'
export const SET_LOGIN_PASSWORD = 'SET_LOGIN_PASSWORD'
export const SHOW_LOGIN_ERROR = 'SHOW_LOGIN_ERROR'
export const HIDE_LOGIN_ERROR = 'HIDE_LOGIN_ERROR'
export const HIDE_LOGIN_ERRORS = 'HIDE_LOGIN_ERRORS'
export const SET_SIGN_UP_AUTH_FLOW_PROFILE_SETTINGS_MODAL =
  'SET_SIGN_UP_AUTH_FLOW_PROFILE_SETTINGS_MODAL'
export const SET_PROFILE_SETTINGS_USER_DISPLAY_NAME = 'SET_PROFILE_SETTINGS_USER_DISPLAY_NAME'
export const SET_PROFILE_SETTINGS_SELF_INDRODUCTION = 'SET_PROFILE_SETTINGS_SELF_INDRODUCTION'
export const SHOW_PROFILE_SETTINGS_ERROR = 'SHOW_PROFILE_SETTINGS_ERROR'
export const HIDE_PROFILE_SETTINGS_ERROR = 'HIDE_PROFILE_SETTINGS_ERROR'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_PROFILE_SETTINGS_MODAL = 'SET_PROFILE_SETTINGS_MODAL'
export const HIDE_PROFILE_SETTINGS_ERRORS = 'HIDE_PROFILE_SETTINGS_ERRORS'
export const SET_RESTRICT_EDIT_ARTICLE_MODAL = 'SET_RESTRICT_EDIT_ARTICLE_MODAL'
export const SET_REQUEST_LOGIN_MODAL = 'SET_REQUEST_LOGIN_MODAL'
export const SET_USERS_ALIS_TOKEN = 'SET_USERS_ALIS_TOKEN'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_NOTIFICATIONS_LAST_EVALUATED_KEY = 'SET_NOTIFICATIONS_LAST_EVALUATED_KEY'
export const SET_UNREAD_NOTIFICATION = 'SET_UNREAD_NOTIFICATION'
export const SET_SEARCH_USERS = 'SET_SEARCH_USERS'
export const SET_SEARCH_USERS_IS_LAST_PAGE = 'SET_SEARCH_USERS_IS_LAST_PAGE'
export const SET_SEARCH_USERS_PAGE = 'SET_SEARCH_USERS_PAGE'
export const RESET_SEARCH_USERS = 'RESET_SEARCH_USERS'
export const RESET_SEARCH_USERS_PAGE = 'RESET_SEARCH_USERS_PAGE'
export const SET_SEARCH_USERS_IS_FETCHING = 'SET_SEARCH_USERS_IS_FETCHING'
export const RESET_SEARCH_USERS_IS_LAST_PAGE = 'RESET_SEARCH_USERS_IS_LAST_PAGE'
export const SET_TIP_MODAL = 'SET_TIP_MODAL'
export const SET_TIP_FLOW_SELECT_TIP_AMOUNT_MODAL = 'SET_TIP_FLOW_SELECT_TIP_AMOUNT_MODAL'
export const SET_TIP_FLOW_CONFIRMATION_MODAL = 'SET_TIP_FLOW_CONFIRMATION_MODAL'
export const HIDE_TIP_FLOW_MODAL_CONTENT = 'HIDE_TIP_FLOW_MODAL_CONTENT'
export const SET_TIP_TOKEN_AMOUNT = 'SET_TIP_TOKEN_AMOUNT'
export const SET_TIP_TRANSACTIONS_INFO = 'SET_TIP_TRANSACTIONS_INFO'
export const SET_WITH_DRAW_TRANSACTIONS_INFO = 'SET_WITH_DRAW_TRANSACTIONS_INFO'
export const SET_TIP_FLOW_COMPLETED_MODAL = 'SET_TIP_FLOW_COMPLETED_MODAL'
export const SET_WALLET_PBKDF2_KEY = 'SET_WALLET_PBKDF2_KEY'
export const RESET_NOTIFICATION_DATA = 'RESET_NOTIFICATION_DATA'
export const SET_SIGN_UP_AUTH_FLOW_COMPLETED_PHONE_NUMBER_AUTH_MODAL =
  'SET_SIGN_UP_AUTH_FLOW_COMPLETED_PHONE_NUMBER_AUTH_MODAL'
export const SET_SIGN_UP_AUTH_FLOW_NOT_COMPLETED_PHONE_NUMBER_AUTH_MODAL =
  'SET_SIGN_UP_AUTH_FLOW_NOT_COMPLETED_PHONE_NUMBER_AUTH_MODAL'
export const SET_FIRST_PROCESS_MODAL = 'SET_FIRST_PROCESS_MODAL'
export const SET_FIRST_PROCESS_LIKED_ARTICLE_MODAL = 'SET_FIRST_PROCESS_LIKED_ARTICLE_MODAL'
export const SET_FIRST_PROCESS_TIPPED_ARTICLE_MODAL = 'SET_FIRST_PROCESS_TIPPED_ARTICLE_MODAL'
export const SET_FIRST_PROCESS_GOT_TOKEN_MODAL = 'SET_FIRST_PROCESS_GOT_TOKEN_MODAL'
export const SET_FIRST_PROCESS_CREATED_ARTICLE_MODAL = 'SET_FIRST_PROCESS_CREATED_ARTICLE_MODAL'
export const SET_IS_FETCHING_USER_ARTICLES = 'SET_IS_FETCHING_USER_ARTICLES'
export const SET_IS_FETCHING_USER_POPULAR_ARTICLES = 'SET_IS_FETCHING_USER_POPULAR_ARTICLES'
export const SET_USER_ARTICLES_CURRENT_USER_ID = 'SET_USER_ARTICLES_CURRENT_USER_ID'
export const SET_MOBILE_EDITOR_HEADER_POST_ARTICLE_MODAL =
  'SET_MOBILE_EDITOR_HEADER_POST_ARTICLE_MODAL'
export const SET_SELECT_PAYMENT_PRICE = 'SET_SELECT_PAYMENT_PRICE'
export const SET_SELECT_PAYMENT_TITLE = 'SET_SELECT_PAYMENT_TITLE'
export const SET_SELECT_PAYMENT_BODY = 'SET_SELECT_PAYMENT_BODY'
export const SET_PURCHASED_ARTICLE_IDS = 'SET_PURCHASED_ARTICLE_IDS'
export const SET_CONFIRM_PURCHASE_ARTICLE_MODAL = 'SET_CONFIRM_PURCHASE_ARTICLE_MODAL'
export const SET_WITHDRAWAL_DETAIL_MODAL = 'SET_WITHDRAWAL_DETAIL_MODAL'
export const SET_WITHDRAWAL_DETAILS = 'SET_WITHDRAWAL_DETAILS'
export const SET_INPUT_WITHDRAW_AUTH_CODE_MODAL = 'SET_INPUT_WITHDRAW_AUTH_CODE_MODAL'
export const SET_INPUT_WITHDRAW_AUTH_CODE_MODAL_VALUES = 'SET_INPUT_WITHDRAW_AUTH_CODE_MODAL_VALUES'
export const SET_APPLICATIONS = 'SET_APPLICATIONS'
export const SET_APPLICATION = 'SET_APPLICATION'
export const SET_CONNECTED_APPLICATIONS = 'SET_CONNECTED_APPLICATIONS'
export const DELETE_CONNECTED_APPLICATION = 'DELETE_CONNECTED_APPLICATION'
export const SET_MUTE_USERS = 'SET_MUTE_USERS'
export const SET_MUTE_USER = 'SET_MUTE_USER'
export const DELETE_MUTE_USER = 'DELETE_MUTE_USER'
export const SET_LOGIN_FROM = 'SET_LOGIN_FROM'
export const SET_LABO_LICENSE_TOKEN_CERTIFICATE_MODAL = 'SET_LABO_LICENSE_TOKEN_CERTIFICATE_MODAL'
export const SET_LABO_LICENSE_TOKEN_CERTIFICATE_MODAL_VALUES =
  'SET_LABO_LICENSE_TOKEN_CERTIFICATE_MODAL_VALUES'
export const SET_LABO_LICENSE_TOKEN_TRANSFER_MODAL = 'SET_LABO_LICENSE_TOKEN_TRANSFER_MODAL'
export const SET_LABO_LICENSE_TOKEN_TRANSFER_MODAL_VALUES =
  'SET_LABO_LICENSE_TOKEN_TRANSFER_MODAL_VALUES'

// article
export const SET_POPULAR_ARTICLES = 'SET_POPULAR_ARTICLES'
export const SET_TMP_POPULAR_ARTICLES = 'SET_TMP_POPULAR_ARTICLES'
export const SET_TMP_POPULAR_ARTICLE_IDS = 'SET_TMP_POPULAR_ARTICLE_IDS'
export const DELETE_TMP_POPULAR_ARTICLES = 'DELETE_TMP_POPULAR_ARTICLES'
export const SET_NEW_ARTICLES = 'SET_NEW_ARTICLES'
export const DELETE_ARTICLE_DRAFT = 'DELETE_ARTICLE_DRAFT'
export const SET_TMP_NEW_ARTICLES = 'SET_TMP_NEW_ARTICLES'
export const DELETE_TMP_NEW_ARTICLES = 'DELETE_TMP_NEW_ARTICLES'
export const SET_ARTICLE = 'SET_ARTICLE'
export const SET_ARTICLE_ID = 'SET_ARTICLE_ID'
export const SET_ARTICLE_DETAIL = 'SET_ARTICLE_DETAIL'
export const SET_ARTICLE_ORIGINAL_BODY = 'SET_ARTICLE_ORIGINAL_BODY'
export const SET_LIKES_COUNT = 'SET_LIKES_COUNT'
export const SET_LIKES_COUNT_TO_ARTICLE = 'SET_LIKES_COUNT_TO_ARTICLE'
export const SET_PUBLIC_ARTICLES = 'SET_PUBLIC_ARTICLES'
export const SET_DRAFT_ARTICLES = 'SET_DRAFT_ARTICLES'
export const SET_ARTICLE_CONTENT_EDIT_HISTORIES = 'SET_ARTICLE_CONTENT_EDIT_HISTORIES'
export const UPDATE_TITLE = 'UPDATE_TITLE'
export const UPDATE_BODY = 'UPDATE_BODY'
export const UPDATE_SUGGESTED_THUMBNAILS = 'UPDATE_SUGGESTED_THUMBNAILS'
export const UPDATE_THUMBNAIL = 'UPDATE_THUMBNAIL'
export const SET_IS_SAVING = 'SET_IS_SAVING'
export const SET_GOT_ARTICLE_DATA = 'SET_GOT_ARTICLE_DATA'
export const SET_PUBLIC_ARTICLES_LAST_EVALUATED_KEY = 'SET_PUBLIC_ARTICLES_LAST_EVALUATED_KEY'
export const SET_DRAFT_ARTICLES_LAST_EVALUATED_KEY = 'SET_DRAFT_ARTICLES_LAST_EVALUATED_KEY'
export const SET_CURRENT_USER_INFO = 'SET_CURRENT_USER_INFO'
export const SET_USER_ARTICLES = 'SET_USER_ARTICLES'
export const SET_USER_POPULAR_ARTICLES = 'SET_USER_POPULAR_ARTICLES'
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_USER_ARTICLES_LAST_EVALUATED_KEY = 'SET_USER_ARTICLES_LAST_EVALUATED_KEY'
export const RESET_USER_ARTICLES = 'RESET_USER_ARTICLES'
export const RESET_USER_ARTICLES_LAST_EVALUATED_KEY = 'RESET_USER_ARTICLES_LAST_EVALUATED_KEY'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_WALLET_PASSWORD = 'RESET_WALLET_PASSWORD'
export const SET_HAS_NEW_ARTICLES_LAST_EVALUATED_KEY = 'SET_HAS_NEW_ARTICLES_LAST_EVALUATED_KEY'
export const SET_HAS_PUBLIC_ARTICLES_LAST_EVALUATED_KEY =
  'SET_HAS_PUBLIC_ARTICLES_LAST_EVALUATED_KEY'
export const SET_IS_LIKED_ARTICLE = 'SET_IS_LIKED_ARTICLE'
export const SET_IS_EDITED = 'SET_IS_EDITED'
export const SET_IS_EDITED_TITLE = 'SET_IS_EDITED_TITLE'
export const SET_IS_EDITED_BODY = 'SET_IS_EDITED_BODY'
export const SET_SAVE_STATUS = 'SET_SAVE_STATUS'
export const SET_ARTICLE_COMMENTS = 'SET_ARTICLE_COMMENTS'
export const SET_ARTICLE_COMMENTS_LAST_EVALUATED_KEY = 'SET_ARTICLE_COMMENTS_LAST_EVALUATED_KEY'
export const ADD_ARTICLE_COMMENT = 'ADD_ARTICLE_COMMENT'
export const SET_ARTICLE_COMMENT_LIKED_COMMENT_IDS = 'SET_ARTICLE_COMMENT_LIKED_COMMENT_IDS'
export const UPDATE_ARTICLE_COMMENTS_BY_COMMENT_IDS = 'UPDATE_ARTICLE_COMMENTS_BY_COMMENT_IDS'
export const DELETE_ARTICLE_COMMENT = 'DELETE_ARTICLE_COMMENT'
export const SET_SEARCH_ARTICLES = 'SET_SEARCH_ARTICLES'
export const SET_SEARCH_ARTICLES_IS_LAST_PAGE = 'SET_SEARCH_ARTICLES_IS_LAST_PAGE'
export const SET_SEARCH_ARTICLES_PAGE = 'SET_SEARCH_ARTICLES_PAGE'
export const RESET_SEARCH_ARTICLES = 'RESET_SEARCH_ARTICLES'
export const RESET_SEARCH_ARTICLES_PAGE = 'RESET_SEARCH_ARTICLES_PAGE'
export const SET_SEARCH_ARTICLES_IS_FETCHING = 'SET_SEARCH_ARTICLES_IS_FETCHING'
export const RESET_SEARCH_ARTICLES_IS_LAST_PAGE = 'RESET_SEARCH_ARTICLES_IS_LAST_PAGE'
export const SET_TOPICS = 'SET_TOPICS'
export const SET_ARTICLES_PAGE = 'SET_ARTICLES_PAGE'
export const SET_ARTICLES_IS_LAST_PAGE = 'SET_ARTICLES_IS_LAST_PAGE'
export const SET_TMP_ARTICLES_IS_LAST_PAGE = 'SET_TMP_ARTICLES_IS_LAST_PAGE'
export const SET_TMP_POPULAR_ARTICLES_IS_LAST_PAGE = 'SET_TMP_POPULAR_ARTICLES_IS_LAST_PAGE'
export const RESET_ARTICLE_DATA = 'RESET_ARTICLE_DATA'
export const SET_ARTICLE_TYPE = 'SET_ARTICLE_TYPE'
export const SET_ARTICLE_TOPIC = 'SET_ARTICLE_TOPIC'
export const RESET_ARTICLE_TOPIC = 'RESET_ARTICLE_TOPIC'
export const SET_TOPIC_DISPLAY_NAME = 'SET_TOPIC_DISPLAY_NAME'
export const SET_FETCHING_ARTICLE_TOPIC = 'SET_FETCHING_ARTICLE_TOPIC'
export const SET_ARTICLE_TAGS = 'SET_ARTICLE_TAGS'
export const UPDATE_TAGS = 'UPDATE_TAGS'
export const SET_TAG_ARTICLES = 'SET_TAG_ARTICLES'
export const SET_TAG_ARTICLES_IS_LAST_PAGE = 'SET_TAG_ARTICLES_IS_LAST_PAGE'
export const SET_TAG_ARTICLES_PAGE = 'SET_TAG_ARTICLES_PAGE'
export const SET_TAG_ARTICLES_IS_FETCHING = 'SET_TAG_ARTICLES_IS_FETCHING'
export const RESET_TAG_ARTICLES_DATA = 'RESET_TAG_ARTICLES_DATA'
export const SET_TAG_ARTICLES_CURRENT_TAG = 'SET_TAG_ARTICLES_CURRENT_TAG'
export const SET_SIGN_UP_AUTH_FLOW_INPUT_USER_ID_MODAL = 'SET_SIGN_UP_AUTH_FLOW_INPUT_USER_ID_MODAL'
export const SET_SIGN_UP_AUTH_FLOW_USER_ID = 'SET_SIGN_UP_AUTH_FLOW_USER_ID'
export const SHOW_SIGN_UP_AUTH_FLOW_INPUT_USER_ID_ERROR =
  'SHOW_SIGN_UP_AUTH_FLOW_INPUT_USER_ID_ERROR'
export const HIDE_SIGN_UP_AUTH_FLOW_INPUT_USER_ID_ERROR =
  'HIDE_SIGN_UP_AUTH_FLOW_INPUT_USER_ID_ERROR'
export const SET_REQUEST_PHONE_NUMBER_VERIFY_MODAL = 'SET_REQUEST_PHONE_NUMBER_VERIFY_MODAL'
export const SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_MODAL =
  'SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_MODAL'
export const SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_PHONE_NUMBER =
  'SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_PHONE_NUMBER'
export const SHOW_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_ERROR =
  'SHOW_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_ERROR'
export const HIDE_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_ERROR =
  'HIDE_REQUEST_PHONE_NUMBER_VERIFY_INPUT_PHONE_NUMBER_ERROR'
export const SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_MODAL =
  'SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_MODAL'
export const SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_AUTH_CODE =
  'SET_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_AUTH_CODE'
export const SHOW_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_ERROR =
  'SHOW_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_ERROR'
export const HIDE_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_ERROR =
  'HIDE_REQUEST_PHONE_NUMBER_VERIFY_INPUT_AUTH_CODE_ERROR'
export const SET_REQUEST_WALLET_PASSWORD_MODAL = 'SET_REQUEST_WALLET_PASSWORD_MODAL'
export const SET_REQUEST_INPUT_WALLET_PASSWORD_MODAL = 'SET_REQUEST_INPUT_WALLET_PASSWORD_MODAL'
export const RESET_REQUEST_WALLET_PASSWORD_MODAL = 'RESET_REQUEST_WALLET_PASSWORD_MODAL'
export const SET_REQUEST_INPUT_WALLET_PASSWORD = 'SET_REQUEST_INPUT_WALLET_PASSWORD'
export const SHOW_REQUEST_INPUT_WALLET_PASSWORD_ERROR = 'SHOW_REQUEST_INPUT_WALLET_PASSWORD_ERROR'
export const HIDE_REQUEST_INPUT_WALLET_PASSWORD_ERROR = 'HIDE_REQUEST_INPUT_WALLET_PASSWORD_ERROR'
export const ADD_ARTICLE_REPLY_COMMENT = 'ADD_ARTICLE_REPLY_COMMENT'
export const DELETE_ARTICLE_REPLY_COMMENT = 'DELETE_ARTICLE_REPLY_COMMENT'
export const SET_IS_FETCHED_PUBLIC_ARTICLE = 'SET_IS_FETCHED_PUBLIC_ARTICLE'
export const SET_EYECATCH_ARTICLES = 'SET_EYECATCH_ARTICLES'
export const SET_RECOMMENDED_ARTICLES = 'SET_RECOMMENDED_ARTICLES'
export const SET_RECOMMENDED_ARTICLES_IS_LAST_PAGE = 'SET_RECOMMENDED_ARTICLES_IS_LAST_PAGE'
export const SET_TMP_RECOMMENDED_ARTICLES_IS_LAST_PAGE = 'SET_TMP_RECOMMENDED_ARTICLES_IS_LAST_PAGE'
export const SET_RECOMMENDED_ARTICLES_IS_TMP_ARTICLES_LAST_PAGE =
  'SET_RECOMMENDED_ARTICLES_IS_TMP_ARTICLES_LAST_PAGE'
export const SET_RECOMMENDED_ARTICLES_PAGE = 'SET_RECOMMENDED_ARTICLES_PAGE'
export const SET_RECOMMENDED_ARTICLES_TMP_ARTICLES = 'SET_RECOMMENDED_ARTICLES_TMP_ARTICLES'
export const SET_RECOMMENDED_ARTICLES_TMP_ARTICLE_IDS = 'SET_RECOMMENDED_ARTICLES_TMP_ARTICLE_IDS'
export const DELETE_RECOMMENDED_ARTICLES_TMP_ARTICLES = 'DELETE_RECOMMENDED_ARTICLES_TMP_ARTICLES'
export const SET_TOPIC_RECOMMENDED_ARTICLES = 'SET_TOPIC_RECOMMENDED_ARTICLES'
export const SET_IS_FETCHING_TAG_ARTICLES = 'SET_IS_FETCHING_TAG_ARTICLES'
export const RESET_ARTICLE_COMMENTS_LAST_EVALUATED_KEY = 'RESET_ARTICLE_COMMENTS_LAST_EVALUATED_KEY'
export const UPDATE_ARTICLE_PRICE = 'UPDATE_ARTICLE_PRICE'
export const SET_PURCHASED_ARTICLES_LAST_EVALUATED_KEY = 'SET_PURCHASED_ARTICLES_LAST_EVALUATED_KEY'
export const SET_PURCHASED_ARTICLES = 'SET_PURCHASED_ARTICLES'
export const SET_ARTICLE_CURRENT_PRICE = 'SET_ARTICLE_CURRENT_PRICE'
export const SET_IS_FETCHED_PURCHASED_ARTICLE = 'SET_IS_FETCHED_PURCHASED_ARTICLE'
export const SET_TIP_EYECATCH_ARTICLES = 'SET_TIP_EYECATCH_ARTICLES'
export const SET_TIP_RANKING_ARTICLES = 'SET_TIP_RANKING_ARTICLES'
export const SET_TIP_RANKING_ARTICLES_IS_LAST_PAGE = 'SET_TIP_RANKING_ARTICLES_IS_LAST_PAGE'
export const SET_TIP_RANKING_ARTICLES_IS_TMP_ARTICLES_LAST_PAGE =
  'SET_TIP_RANKING_ARTICLES_IS_TMP_ARTICLES_LAST_PAGE'
export const SET_TIP_RANKING_ARTICLES_PAGE = 'SET_TIP_RANKING_ARTICLES_PAGE'
export const SET_TIP_RANKING_ARTICLES_TMP_ARTICLES = 'SET_TIP_RANKING_ARTICLES_TMP_ARTICLES'
export const DELETE_TIP_RANKING_ARTICLES_TMP_ARTICLES = 'DELETE_TIP_RANKING_ARTICLES_TMP_ARTICLES'
export const SET_ARTICLE_SUPPORTERS = 'SET_ARTICLE_SUPPORTERS'
export const SET_CRYPTO_RANKING_INFO = 'SET_CRYPTO_RANKING_INFO'
export const RESET_CRYPTO_RANKING_INFO = 'RESET_CRYPTO_RANKING_INFO'
export const SET_BCG_RANKING_INFO = 'SET_BCG_RANKING_INFO'
export const RESET_BCG_RANKING_INFO = 'RESET_BCG_RANKING_INFO'

// article modal
export const SET_ARTICLE_DELETE_MODAL = 'SET_ARTICLE_DELETE_MODAL'

// presentation
export const SET_ARTICLE_LIST_SCROLL_HEIGHT = 'SET_ARTICLE_LIST_SCROLL_HEIGHT'
export const SET_NOTIFICATION_LIST_SCROLL_HEIGHT = 'SET_NOTIFICATION_LIST_SCROLL_HEIGHT'
export const SET_SEARCH_ARTICLES_SCROLL_HEIGHT = 'SET_SEARCH_ARTICLES_SCROLL_HEIGHT'
export const SET_SEARCH_USERS_SCROLL_HEIGHT = 'SET_SEARCH_USERS_SCROLL_HEIGHT'
export const SET_TAG_ARTICLES_SCROLL_HEIGHT = 'SET_TAG_ARTICLES_SCROLL_HEIGHT'
export const SET_DEFAULT_HEADER_NAV_HORIZONTAL_SCROLL_POSITION =
  'SET_DEFAULT_HEADER_NAV_HORIZONTAL_SCROLL_POSITION'

// tags
export const SET_SEARCH_TAGS = 'SET_SEARCH_TAGS'
export const SET_SEARCH_TAGS_SCROLL_HEIGHT = 'SET_SEARCH_TAGS_SCROLL_HEIGHT'
export const SET_SEARCH_TAGS_IS_FETCHING = 'SET_SEARCH_TAGS_IS_FETCHING'

// report
export const SET_USER_REPORT_MODAL = 'SET_USER_REPORT_MODAL'
export const SET_USER_REPORT_SELECT_REASON_MODAL = 'SET_USER_REPORT_SELECT_REASON_MODAL'
export const SET_USER_REPORT_SELECT_REASON_REASON = 'SET_USER_REPORT_SELECT_REASON_REASON'
export const SHOW_USER_REPORT_SELECT_REASON_ERROR = 'SHOW_USER_REPORT_SELECT_REASON_ERROR'
export const HIDE_USER_REPORT_SELECT_REASON_ERROR = 'HIDE_USER_REPORT_SELECT_REASON_ERROR'
export const SET_USER_REPORT_INPUT_FREE_TEXT_MODAL = 'SET_USER_REPORT_INPUT_FREE_TEXT_MODAL'
export const SET_USER_REPORT_INPUT_FREE_TEXT_ORIGIN_URL =
  'SET_USER_REPORT_INPUT_FREE_TEXT_ORIGIN_URL'
export const SET_USER_REPORT_INPUT_FREE_TEXT_FREE_TEXT = 'SET_USER_REPORT_INPUT_FREE_TEXT_FREE_TEXT'
export const SHOW_USER_REPORT_INPUT_FREE_TEXT_ERROR = 'SHOW_USER_REPORT_INPUT_FREE_TEXT_ERROR'
export const HIDE_USER_REPORT_INPUT_FREE_TEXT_ERROR = 'HIDE_USER_REPORT_INPUT_FREE_TEXT_ERROR'
export const SET_USER_REPORT_CONFIRMATION_MODAL = 'SET_USER_REPORT_CONFIRMATION_MODAL'
export const RESET_USER_REPORT_DATA = 'RESET_USER_REPORT_DATA'
export const SET_ARTICLE_REPORT_MODAL = 'SET_ARTICLE_REPORT_MODAL'
export const SET_ARTICLE_REPORT_SELECT_REASON_MODAL = 'SET_ARTICLE_REPORT_SELECT_REASON_MODAL'
export const SET_ARTICLE_REPORT_SELECT_REASON_REASON = 'SET_ARTICLE_REPORT_SELECT_REASON_REASON'
export const SHOW_ARTICLE_REPORT_SELECT_REASON_ERROR = 'SHOW_ARTICLE_REPORT_SELECT_REASON_ERROR'
export const HIDE_ARTICLE_REPORT_SELECT_REASON_ERROR = 'HIDE_ARTICLE_REPORT_SELECT_REASON_ERROR'
export const SET_ARTICLE_REPORT_INPUT_FREE_TEXT_MODAL = 'SET_ARTICLE_REPORT_INPUT_FREE_TEXT_MODAL'
export const SET_ARTICLE_REPORT_INPUT_FREE_TEXT_ORIGIN_URL =
  'SET_ARTICLE_REPORT_INPUT_FREE_TEXT_ORIGIN_URL'
export const SET_ARTICLE_REPORT_INPUT_FREE_TEXT_FREE_TEXT =
  'SET_ARTICLE_REPORT_INPUT_FREE_TEXT_FREE_TEXT'
export const SHOW_ARTICLE_REPORT_INPUT_FREE_TEXT_ERROR = 'SHOW_ARTICLE_REPORT_INPUT_FREE_TEXT_ERROR'
export const HIDE_ARTICLE_REPORT_INPUT_FREE_TEXT_ERROR = 'HIDE_ARTICLE_REPORT_INPUT_FREE_TEXT_ERROR'
export const SET_ARTICLE_REPORT_CONFIRMATION_MODAL = 'SET_ARTICLE_REPORT_CONFIRMATION_MODAL'
export const RESET_ARTICLE_REPORT_DATA = 'RESET_ARTICLE_REPORT_DATA'

// badge
export const SET_BADGES = 'SET_BADGES'
export const SET_BADGES_WALLET_ADDRESS = 'SET_BADGES_WALLET_ADDRESS'
export const SET_BADGE_DESCRIPTION_MODAL = 'SET_BADGE_DESCRIPTION_MODAL'
export const SET_BADGE_TYPE_SUPPLY = 'SET_BADGE_TYPE_SUPPLY'

// nft_games
export const SET_NFT_GAMES_RANKING = 'SET_NFT_GAMES_RANKING'
export const SET_NFT_GAMES_RANKING_IS_FETCHING = 'SET_NFT_GAMES_RANKING_IS_FETCHING'
export const SET_NFT_GAME_INFO = 'SET_NFT_GAME_INFO'
export const SET_NFT_GAME_INFO_IS_FETCHING = 'SET_NFT_GAME_INFO_IS_FETCHING'
